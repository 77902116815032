<template>
  <div class="partyAffairs">
    <div class="banner">
      <img src="./img/banner.png" alt="" />
    </div>
    <div class="info">
      <div class="info-l"><img src="./img/guangbo.png" alt="" /></div>
      <div class="info-r">请根据您的需求，自主选择服务内容</div>
    </div>
    <div class="main">
      <div
        :class="['item', { te: index == 0 }]"
        v-for="(item, index) in itemList"
        :key="index"
        @click="toDetail(item, index)"
      >
        <div class="item-l">
          <div class="img"><img :src="item.url" alt="" /></div>
          <div class="txt">{{ item.label }}</div>
        </div>
        <div class="item-r"><img src="./img/arrow.png" alt="" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { gloabalCount } from "@/utils/common";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "partyAffairs",
  data() {
    return {
      fromFlag: "",
      // itemList: [
      //   { url: require("./img/sanhui.png"), label: "三会一课" },
      //   { url: require("./img/topic.png"), label: "主题党日" },
      //   { url: require("./img/comment.png"), label: "党员评议" },
      //   { url: require("./img/school.png"), label: "基层党校" },
      //   { url: require("./img/redPlace.png"), label: "红色阵地" },
      // ],
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    itemList() {
      let list = [
        { url: require("./img/sanhui.png"), label: "三会一课" },
        { url: require("./img/topic.png"), label: "主题党日" },
        { url: require("./img/comment.png"), label: "党员评议" },
        { url: require("./img/school.png"), label: "基层党校" },
      ];
      // 适配钉钉端H5
      // if (this.fromFlag !== "dingding") {
      //   list.push({
      //     url: require("./img/redPlace.png"),
      //     label: "红色阵地",
      //   });
      // }
      return list;
    },
  },
  created() {
    this.fromFlag = this.$route.query.fromFlag;
  },
  mounted() {
    gloabalCount("", 48, 1);
  },
  methods: {
    toDetail(item, index) {
      if (index == 0) {
        this.$router.push({
          name: "partyAffairsList",
          query: {
            fromFlag: this.fromFlag,
          },
        });
      }
      if (index == 1) {
        this.$router.push({
          name: "topicParty",
          query: {
            fromFlag: this.fromFlag,
          },
        });
      }
      if (index == 2) {
        this.$router.push({
          name: "rightsComment",
        });
      }
      if (index == 3) {
        this.$router.push({
          name: "grassRootsPartySchool",
          query: {
            fromFlag: this.fromFlag,
          },
        });
      }
      // if (index == 4) {
      //   wx.miniProgram.navigateTo({
      //     url: `/pages/index/switch`,
      //   });
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.partyAffairs {
  min-height: 100vh;
}
.partyAffairs {
  .banner {
    height: 320px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .info {
    display: flex;
    padding: 28px 36px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    .info-l {
      width: 40px;
      height: 40px;
      margin-right: 16px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info-r {
      font-size: 28px;
      font-weight: 400;
      color: #1a1c34;
      line-height: 40px;
    }
  }
  .main {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 42px 56px 42px 52px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .item-l {
        display: flex;
        align-items: center;
        .img {
          width: 140px;
          height: 142px;
          margin-right: 74px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          font-size: 32px;
          font-weight: 600;
          color: #000000;
          line-height: 44px;
        }
      }
      .item-r {
        width: 48px;
        height: 48px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .te {
      .item-l {
        .img {
          text-align: center;
          img {
            width: 128px;
            height: 126px;
          }
        }
      }
    }
  }
}
</style>
